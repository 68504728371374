<template>
  <div class="landing">
    <Tops v-if="types" :title="'注册'"></Tops>
    <van-field v-model="name" class="names" placeholder="请输入姓名" label="名字*" />
    <van-field v-model="iphone" :disabled="iphone" placeholder="请输入手机号码" label="手机号" type="number" />
    <div class="sex">
      <p style="color: #fd0202">性别*</p>
      <van-radio-group v-model="sex" direction="horizontal">
        <van-radio name="1">男</van-radio>
        <van-radio name="0">女</van-radio>
      </van-radio-group>
    </div>
    <van-cell is-link @click="showDate = true">
      <div class="csrq">
        <p style="color: #fd0202">出生日期*</p>
        <p>{{gsDate}}</p>
      </div>
    </van-cell>
    <van-popup v-model="showDate" position="bottom" :style="{ height: '40vh' }">
      <van-datetime-picker
          @confirm="showDate = false"
          @cancel="showDate =false"
          @change="dates"
          v-model="date"
          type="date"
          title="选择年月日"
          :min-date="startdate"
          :max-date="enddate"
          ref="getDate"
      />
    </van-popup>
    <van-cell is-link @click="showAddress = true">
      <div class="csrq">
        <p>地区</p>
        <p v-cloak class="dzzx">{{gsAddress[dzIdx[0]].AreaName}}-{{gsAddress[dzIdx[0]].children[dzIdx[1]].AreaName}}-{{gsAddress[dzIdx[0]].children[dzIdx[1]].children[dzIdx[2]].AreaName}}</p>
      </div>
    </van-cell>
    <van-popup v-model="showAddress" position="bottom" :style="{ height: '40vh' }">
      <van-picker
          @confirm="showAddress=false"
          @cancel="showAddress=false"
          show-toolbar
          title="请选择地址"
          ref="getAddress"
          :value-key="'AreaName'"
          :columns="gsAddress"
          @change="upaddress"
      />
    </van-popup>
    <van-field v-model="detailedAddress" placeholder="选填" label="详细地址" />

    <div style="padding-top: 20px;color: #fd0202;text-align: left; padding-left: 15px;">
      * 是必填项
    </div>

    <div class="xy">
      <div style="display: flex; justify-content: start; align-items: center; padding-left: 10px;">
        <van-checkbox v-model="xyxz">我已阅读并同意以下用户协议和条款：</van-checkbox>
        <p></p>
      </div>
      <div class="xy-right">
        <p @click="$router.push({path: '/xy?idx=0'})">儿童个人信息保护政策</p>
        <p @click="$router.push({path: '/xy?idx=2'})">个人信息保护条款</p>
        <p @click="$router.push({path: '/xy?idx=1'})">用户使用协议</p>
      </div>
    </div>

    <div class="submit">
      <van-button type="info" @click="zc">注册</van-button>
    </div>
    <div id="map" style="height: 0; width: 0;"></div>
    <van-overlay class="mg" :show="iphoneListShow" :lock-scroll='false'>
      <div class="mg-boxs">
        <div class="bt">
          <p style="padding-top: 10px">此手机号码注册过以下用户</p>
          <p>请选择其中一个</p>
        </div>
        <div class="user-box" id="gd">
          <div class="user" v-for="item in iphoneList">
            <p>{{item.CustomerName}}</p>
            <van-button size="small" type="info" @click="sy(item)" >使用</van-button>
          </div>
        </div>
      </div>
    </van-overlay>
    <van-overlay :show="showmgs" style="display: flex;justify-content: center;align-items: center;">
      <van-loading size="24px" color="#0094ff" text-color="#0094ff">加载中...</van-loading>
    </van-overlay>
  </div>
</template>

<script>
import pf from '@/publicFn/pf'
import { Dialog } from 'vant';
import  datas  from "@/assets/js/dq"
export default {
  name: "wxLanding",
  data() {
    return {
      types: 0,
      name: '',
      iphone: '',
      sex: '',
      showDate: false,
      gsDate: '',
      showAddress: false,
      gsAddress:[
        {
          AreaName: '',
          children: [{
            AreaName: '',
            children: [{
              AreaName: ''
            }]
          }]
        }
      ],
      dzIdx: [0,0,0],
      date: new Date('2000/10/10'),
      // date: '',
      detailedAddress: '',
      iphoneListShow: false,
      showmgs:false,
      xg: false,
      startdate: new Date('1910/01/01'),
      enddate: new Date(),
      iphoneList: [],
      xyxz: '',
      wxData: '', //微信数据
    }
  },
  computed: {

  },
  watch: {
    date(n, o) {
      this.date = new Date(n).pattern('yyyy-MM-dd')
      this.gsDate = this.date
    }
  },
  created() {
    this.gsAddress = datas.arr.map(item => {
      return JSON.parse(JSON.stringify(item).replace(/ListArea/g, 'children'))
    })
    // this.dw(this.gsAddress)
    // alert(this.$store.state.Base.parameters)
    this.clDL()
    // this.getAddress()
  },
  methods: {
    upaddress(p, v) {
      let idx = this.$refs.getAddress.getIndexes()
      clearTimeout(timer)
      let timer = setTimeout(() => {
        this.dzIdx = idx
      }, 500)
    },

    zc() {
      if (this.lingdshow) {
        return;
      }
      if (!this.name) {
        this.$dialog.alert({message: '请输入名字！', theme: 'round-button'})
        return
      }

      if (!this.iphone) {
        this.$dialog.alert({message: '手机号码不能为空！', theme: 'round-button'})
        return
      }


      if (pf.sizeof(this.name) > 18) {
        this.$dialog.alert({message: '名字不可超过6个汉字或18个非汉字字符！', theme: 'round-button'})
        return
      }


      if (!this.sex) {
        this.$dialog.alert({message: '请选择性别！', theme: 'round-button'})
        return
      }

      if (!this.gsDate) {
        this.$dialog.alert({message: '请选择出生日期！', theme: 'round-button'})
        return
      }

      if (pf.getYear(this.gsDate) < 1) {
        this.$dialog.alert({message: '年龄不得小于1岁！', theme: 'round-button'})
        return
      }

      // if (this.yzIphone) {
      //   this.$dialog.alert({message: '手机号码有误！', theme: 'round-button'})
      //   return
      // }
      if (!this.xyxz) {
        this.$dialog.alert({message: '请阅读协议后并点击我已阅读！', theme: 'round-button'})
        return
      }
      let ts = this.$toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 50000,
      });
      this.lingdshow = true
      let wxxx = this.wxData
      let cs = this.$store.state.Base.parameters
      this._http.post('https://interface2.visumall.com/customer/customer.ashx?cmd=Login190826', {
        "token": {
          "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
          "TimeSpan": "1535942069232"
        },
        CustomerAreaId: this.gsAddress[this.dzIdx[0]].children[this.dzIdx[1]].children[this.dzIdx[2]].AreaId,
        CustomerAddress: this.detailedAddress,
        CustomerAreaIndex: this.dzIdx.join(','),
        "SearchCondition": {
          "CustomerTelFull": this.iphone,
          "CustomerNameFull": this.name,
          "CustomerGender": this.sex,
          "CustomerBirthday": this.date,
          CustomerWeiXinOpenId: wxxx.openid,
          CustomerWeiXinUnionId: cs.uid,
          ChooseCompanyId: cs.CompanyId
        }
      })
          .then(res => {
            ts.clear()
            if (res.data.Login190826Result) {
              this.$store.commit('user/upFamilyList', [{CustomerName: this.name, CustomerId: res.data.Login190826Result.CustomerId}])
              if (this.isSchool) {
                this.upSchool(null , res.data.Login190826Result)
              }else {
                this.$store.commit('user/upCustomer', JSON.parse(JSON.stringify(res.data.Login190826Result)))
                this.$dialog.alert({message: '成功！', theme: 'round-button'})
                    .then(() => {
                      wx.miniProgram.navigateBack({ delta: 1 })
                    })
              }
            }else {
              this.lingdshow = false
              this.$dialog.alert({message: '注册失败，请稍后再试！', theme: 'round-button'})
            }
          })
    },

    clDL() {
      // return
      let cs = this.$store.state.Base.parameters
      // alert(cs.code)
      if (cs.code) {
        this.getWxOpid()
        this.iphone = cs.tel
        this.getcsList(cs.tel)
      }else {
        let str1 = `https://wx2021.visumall.com?tel=${cs.tel}&CompanyId=${cs.CompanyId}&type=landing&appid=${cs.appid}&uid=${cs.uid}`
        // let str1 = `http://192.168.0.127:8080?tel=${cs.tel}&CompanyId=${cs.CompanyId}&type=landing&appid=${cs.appid}&uid=${cs.uid}`
        let str = window.encodeURIComponent(str1)
        let urls = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${cs.appid}&redirect_uri=${str}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect`
        window.location = urls
      }
    },

    getAddress() {
      this._http.post('https://interface2.visumall.com/business/Area.ashx?cmd=GetListStruct', {
        "token":{
          "TimeSpan": 532618542085,
          "sToken": "532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        "AreaParentId": "00000000-0000-0000-0000-000000000000"
      })
          .then(res => {
            let dzcity = res.data.GetListResult.map(item => {
              return JSON.parse(JSON.stringify(item).replace(/ListArea/g, 'children'))
            })
            this.gsAddress = dzcity
            this.dw(dzcity)
          })
    }, //获取地址

    dw (arr) {
      let map = new AMap.Map("map", {
        resizeEnable: true,
        center: [116.397428, 39.90923],
        zoom: 13
      });
      //实例化城市查询类
      let _this = this
      let  citysearch = new AMap.CitySearch();
      //自动获取用户IP，返回当前城市
      citysearch.getLocalCity(function(status, result) {
        if (status === 'complete' && result.info === 'OK') {
          if (result && result.city && result.bounds) {
            let city = result.city;
            let sf = result.province;
            let sm = []
            for (let i = 0; i < arr.length; i++) {
              if (arr[i].AreaName == sf) {
                sm[0] = i
                for (let j = 0; j < arr[i].children.length; j++) {
                  if (arr[i].children[j].AreaName == city) {
                    sm[1] = j
                    sm[2] = 0
                    break
                  }
                }
              }
            }
            _this.dzIdx = sm
          }
        }
      })
    }, //获取用户定位

    getWxOpid() {
      let cs = this.$store.state.Base.parameters
      this._http.post('https://interface2.visumall.com/OtherSystem/WxHttps.ashx?cmd=wx_getUserInfo', {
        "token":{
          "TimeSpan":532618542085,
          "sToken":"532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        code: cs.code,
        appid: cs.appid
      })
          .then(res => {
            this.wxData = res.data.WxuserinfoResult
            console.log(res)
          })
    }, //获取公众号OPID

    getcsList(ip) {
      this._http.post('https://interface2.visumall.com/customer/customer.ashx?cmd=GetNameListByTel', {
        "token":{
          "TimeSpan": 532618542085,
          "sToken": "532618542085hvNRUjZTfkyFRrQGb0oN0g6996262EB1AAC747"
        },
        "CustomerTel": ip,
      })
          .then(res => {
            if (res.data.GetNameListByTelResult && res.data.GetNameListByTelResult.length > 0) {
              this.iphoneListShow = true
              this.iphoneList = res.data.GetNameListByTelResult
            }
          })
          .catch(err => {

          })
    }, //获取用户的以前注册的账号

    sy(item) {
      let cs = this.$store.state.Base.parameters
      Dialog.confirm({
        title: '提示',
        message: '是否使用此账号？',
        theme: 'round-button'
      })
          .then(() => {
            item.CustomerWeiXinOpenId = this.wxData.openid
            item.CustomerWeiXinUnionId = cs.uid
            this._http.post('https://interface2.visumall.com/customer/customer.ashx?cmd=Update', {
              "token": {
                "sToken": "8BED23DE028E0E9966B8CE9E8F77DB89",
                "TimeSpan": "1535942069232"
              },
              o: item
            })
                .then(res => {
                  if (res.data.UpdateResult == 1) {
                    wx.miniProgram.navigateBack({ delta: 1 })
                  }else {
                    this.$dialog.alert({message: '注册失败，请稍后再试！', theme: 'round-button'})
                  }
                })
          })
          .catch(() => {
            // on cancel
          });
    },//使用以前的号码

    dates(e) {
      // console.log(this.$refs.getDate.getValues())
    }
  },

  //生命周期
  destroyed() {

  }
}
</script>

<style lang="scss">
.landing {
  width: 100vw;
  height: 95vh;
  padding-bottom: 5vh;
  background: #f6f7f9;
  overflow: hidden;
  overflow-y: auto;
  position: relative;
}
.logo {
  display: block;
  width: 45vw;
  height: auto;
  margin: 8vh auto;
}
.sex {
  display: flex;
  align-items: center;
  justify-content: start;
  background: #ffffff;
  padding: 10px 16px;
  font-size: 14px;
  color: #646566;
  margin-bottom: 1px;
  p {
    padding-right: 70px;
  }
}
.csrq {
  display: flex;
  justify-content: start;
  color: #646566;
  p:nth-child(1) {width: calc(6em + 12px)}
}
.dzzx {
  width: calc(100% - 6em - 12px);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.submit {
  display: flex;
  justify-content: center;
  width: 100vw;
  padding-top: 50px;
}
.xy {
  padding-top: 3vh;
  font-size: 11px;
}
.mg-boxs {
  background: #ffffff;
  width: 90vw;
  border-radius: 10px;
  padding: .2rem 0;
  overflow: hidden;
  z-index: 9999;
  .xyb {margin-top: .35rem;}
  .van-button {
    height: 35px;
    border-radius: 8px;
  }
}
.mg {
  display: flex; align-items: center; justify-content: center;
  font-size: 14px;
}
.xy-right {
  text-align: left;
  color: #1010e5;
  padding-left: 40px;
  p {
    margin-top: .06rem;
  }
}
.user {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: .1rem 0;
  background: #eeeeee;
  width: 70vw;
  margin: 10px auto;
  border-radius: 15px;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: center;
  }
  p:nth-child(1) {width: 8em;}
  p:nth-child(2) {width: 40vw;}
}
.user-box {
  height: 30vh;
  overflow: auto;
  padding-top: 10px;
  margin-top: 10px;
}
.bt {
  width: 80vw;
  margin: 0 auto;
  border: 1px dotted #00aeff;
  border-radius: 10px;
  padding-bottom: 10px;
  color: #ff0000;
  font-size: 12px;
}
.names {}
.names .van-field__label span {color:#fd0202;}
</style>
